import "core-js/stable";
import "regenerator-runtime/runtime";
import anime from 'animejs/lib/anime.es.js';
import '../src/application';

let desktopMinWidth = 992;

function showAnekdot(index) {
  anime.timeline({ loop: false }).add({
    targets: `.anekdotes__anekdot:nth-of-type(${index + 1})>.anekdot-word`,
    opacity: [0, 1],
    easing: "easeInOutQuad",
    duration: 2250,
    delay: (_, i) => 150 * (i + 1)
  })
}

//baner loading blur image

if ($('.baner').length) {
  let banner = document.querySelector('.baner');
  let imageWrapper = document.querySelector('.baner__image-wrapper ');
  var img = new Image();
  img.src = banner.dataset.image;
  img.onload = function () {
    img.classList.add('baner__image');
    imageWrapper.appendChild(img);
    imageWrapper.classList.add("baner__image-wrapper--loaded");
  };
}


$(document).ready(function () {
  let isSliderPlayed = false;

  if ($(window).width() < 768) {
    $('.logo-carousel').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false
          }
        },
      ]
    });

    $('.logo-carousel').slick('slickPause');
  }


  let animation = () => {

    let scrollTop = $(window).scrollTop();
    let windowHeight = $(window).height();

    $(".baner, .icon-blocks, .text-section--animated, .text-section__animated-block, .reviews__animated-block, .contact-show-on-scroll").each((_, element) => {
      let offset = $(element).offset().top;
      let windowOffset = scrollTop + .9 * windowHeight;
      if (windowOffset > offset) {

        //contact btn animation on scroll
        if ($(element).hasClass('contact-show-on-scroll') &&
          $(element).attr("data-anim") !== 'true') {
          anime.timeline({ loop: false }).add({
            targets: '#contact-button-text',
            opacity: [1, 0],
            easing: "easeInOutQuad",
            duration: 500,
            delay: 3000,
            loopComplete: function () {
              setTimeout(() => {
                $('.contact-show-on-scroll').addClass('main-contact-animation--link-visible');
              }, 500)
            }
          });
        }
        $(element).attr("data-anim", "true");
      };

      if ($(window).width() < 768) {
        const clientsSliderOffset = $('.logo-carousel').offset();

        if (clientsSliderOffset && (windowOffset > clientsSliderOffset.top) && !isSliderPlayed) {
          $('.logo-carousel').slick('slickPlay');
          isSliderPlayed = true;
        };
      }
    });
  }
  const setFixedMenu = () => {
    if ($(window).scrollTop() > 0) {
      $('.header').addClass('header--fixed');
      $('.over-slider__logo').addClass('over-slider__logo--fixed')
    }
    else {
      $('.header').removeClass('header--fixed');
      $('.over-slider__logo').removeClass('over-slider__logo--fixed')
    }
  }
  animation();
  setFixedMenu();

  $(window).scroll(() => {
    animation();
    setFixedMenu();
  });

  anime.timeline({ loop: false }).add({
    targets: '.baner-title__letter',
    opacity: [0, 1],
    easing: "easeInOutQuad",
    duration: 1125,
    delay: (_, i) => 70 * (i + 1)
  }).add({
    targets: '.anekdotes',
    opacity: [0, 1],
    easing: "easeInOutQuad",
    duration: 500,
    delay: 0,
  }).add({
    targets: '.baner__button',
    opacity: [0, 1],
    easing: "easeInOutQuad",
    duration: 500,
    delay: 0,
  }).add({
    targets: '#banner-contact-text',
    opacity: [1, 0],
    easing: "easeInOutQuad",
    duration: 300,
    delay: 2000,
    loopComplete: function () {
      setTimeout(() => {
        $('#banner-contact').addClass('main-contact-animation--link-visible');
        $('.banner-tel').addClass('banner-tel--visible');
        $('.banner-content__phone-description').addClass('banner-content__phone-description--visible');
      }, 500)
    }
  });


  $('.anekdotes').slick({
    slidesToShow: 1,
    autoplay: true,
    fade: true,
    arrows: false,
    useCSS: false,
    useTransform: false,
    autoplaySpeed: 6000,
  }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    showAnekdot(nextSlide);
  });
  setTimeout(function () {
    showAnekdot(0);
  }, 300)

  let animationTimeout;

  const onChangeImageSlide = (categoryId) => {
    if (!!animationTimeout) {
      clearTimeout(animationTimeout)
    }
    $('.slider-images__animate-item--show').addClass('slider-images__animate-item--hide');
    $('.slider-images__animate-item').removeClass('slider-images__animate-item--show');
    $(categoryId + '-image').addClass('slider-images__animate-item--show');

    animationTimeout = setTimeout(function () {
      $('.slider-images__animate-item').removeClass('slider-images__animate-item--hide');
    }, 450)
  }

  const setDefaultFeaturedStory = () => {
    $('.story-nav').removeClass('sub-menu__item-link--active');
    $('.featured-story').removeClass('featured-story--active');
    $('.story-nav[data-story="featured-story-1"]').addClass('sub-menu__item-link--active');
    $('#featured-story-1').addClass('featured-story--active')
  }

  const onChangeSlide = (categoryId) => {
    $('.sub-menu-items__item').removeClass('sub-menu-items__item--show');
    $(categoryId).addClass('sub-menu-items__item--show');

    onChangeImageSlide(categoryId);
  }

  if ($(window).width() >= desktopMinWidth) {

    $('.slider-main-menu-item').on('click', function () {
      const categoryId = '#' + $(this).attr('data-category');
      if ($(categoryId).hasClass('sub-menu-items__item--show')) {
        return
      }
      if (!$('#featured-story-1').hasClass('featured-story--active')) {
        setDefaultFeaturedStory();
      }
      $('.slider-main-menu-item').removeClass('nav-list__link--active');
      $(this).addClass('nav-list__link--active');
      onChangeSlide(categoryId);
    });

    $('.story-nav').on('click', function () {
      const storyId = '#' + $(this).attr('data-story');
      const imageStoryId = '#' + $(this).attr('data-story-img');
      if ($(storyId).hasClass('featured-story--active')) {
        return
      }
      $('.featured-story').removeClass('featured-story--active');
      $('.story-nav').removeClass('sub-menu__item-link--active');
      $(this).addClass('sub-menu__item-link--active');
      $(storyId).addClass('featured-story--active')
      onChangeImageSlide(imageStoryId);
    })

    $('.navbar-nav__item').on('click', function () {
      const category = $(this).attr('data-category');
      const categoryId = '#' + category;
      $('.over-slider').addClass('over-slider--opened');
      $('body').addClass('over-slider-opened');
      $('.slider-main-menu-item[data-category=' + category + ']').addClass('nav-list__link--active');

      onChangeSlide(categoryId);
    });


    $('.close-over-slider, .over-slider__overlay').on('click', function () {
      $('.over-slider').removeClass('over-slider--opened');
      $('body').removeClass('over-slider-opened');
      $('.slider-main-menu-item').removeClass('nav-list__link--active');
      $('.dropdown-description').collapse('hide');
      $('#services-accordion').animate({
        scrollTop: 0
      }, 300);
      if (!$('#featured-story-1').hasClass('featured-story--active')) {
        setDefaultFeaturedStory();
      }
    });

    $('.icon-block').on('click', function () {
      $('.over-slider').addClass('over-slider--opened');
      $('body').addClass('over-slider-opened');
      $('.slider-main-menu-item[data-category="services"]').addClass('nav-list__link--active');
      onChangeSlide('#services');
      setTimeout(() => {
        $('#services-accordion').animate({
          scrollTop: $($(this).attr('data-target') + '-heading').position().top
        }, 300);
      }, 500);

    })

    $('.text-section__desktop_only .button').on('click', function () {
      $('.over-slider').addClass('over-slider--opened');
      $('body').addClass('over-slider-opened');
      $('.slider-main-menu-item[data-category="who-we-are"]').addClass('nav-list__link--active');
      onChangeSlide('#who-we-are');
    });
  }
  else {

    $('.icon-block__flip-card').click(function () {
      if ($(window).width() < 768) {
        if (!$(this).hasClass('icon-block__flip-card--flipped')) {
          $(this).addClass('icon-block__flip-card--flipped');
        }
        else {
          $(this).removeClass('icon-block__flip-card--flipped')
        }
      }
    })

    $('.icon-block__back').on('click', function (e) {
      e.stopPropagation();
      $('#main_navbar').collapse('show');
      $('.navbar-nav__item[data-category="services"]').parent().find('.sub-nav-list').addClass('sub-nav-list--show');
      $('.sub-nav-list__item[data-mobile-service="' + $(this).attr('aria-controls') + '"]')
        .find('.sub-nav-list__description').addClass('sub-nav-list__description--visible');

      setTimeout(() => {
        $('.header').animate({
          scrollTop: $('.sub-nav-list__item[data-mobile-service="' + $(this).attr('aria-controls') + '"]').position().top
        }, 300);
      }, 500);

    })

    $('.navbar-nav__item').click(function (e) {
      e.stopPropagation();
      const isOpen = $(this).parent().find('.sub-nav-list').hasClass('sub-nav-list--show');
      $('.sub-nav-list').removeClass('sub-nav-list--show');
      $(this).parent().find('.sub-nav-list').toggleClass('sub-nav-list--show', !isOpen);
    });

    $('.sub-nav-list__link').click(function (e) {
      e.stopPropagation();
      const isOpen = $(this).parent().find('.sub-nav-list__description').hasClass('sub-nav-list__description--visible');
      $('.sub-nav-list__description').removeClass('sub-nav-list__description--visible');
      $(this).parent().find('.sub-nav-list__description').toggleClass('sub-nav-list__description--visible', !isOpen);
    });

    $(document).click(function () {
      $('.sub-nav-list').removeClass('sub-nav-list--show');
      $('.sub-nav-list__description').removeClass('sub-nav-list__description--visible');
    });

    $('.sub-nav-list').click(function (e) {
      e.stopPropagation();
    })
  }

  // $('.scroll-down-button').click(function () {
  //   $('html, body').animate({
  //     scrollTop: $(".second-section").offset().top
  //   }, 1000);
  // })

  //in open menu
  $('#main_navbar').on('show.bs.collapse', function () {
    $('.header').addClass('header--opened');
  });
  $('#main_navbar').on('hide.bs.collapse', function () {
    $('.header').removeClass('header--opened');
  });
});
